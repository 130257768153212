// stylesheets
// stylesheets
import './webcore/lib/mcb/css/normalize.css';
import './webcore/lib/mcb/css/main.css';
import './webcore/css/core.css';
import 'salesforce-lightning-design-system/assets/styles/salesforce-lightning-design-system.css';
import 'mc-assets/dist/css/mcb-to-slds-transition.css';
import 'mc-assets/dist/css/socialstudio-icons.css';
// libraries
import Backbone from 'backbone';
import numeral from 'numeral';
import $ from 'jquery';
import 'underscore';
import 'twitter-text';
import 'mcui/extensions';
import 'i18n/webcore';
import 'mcui/lookups/all';
import './vendor/backbone.routefilter';
// moment + locales
import 'moment-timezone';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/ja';
import 'moment/locale/pt-br';

// configuration
Backbone.$ = $;

// numeral: each language that's missing needs to be included and registered here
numeral.language('de', require('numeral/languages/de'));
numeral.language('fr', require('numeral/languages/fr'));
numeral.language('it', require('numeral/languages/it'));
numeral.language('ja', require('numeral/languages/ja'));